import React from "react";

class NavigationLink extends React.Component {
  /*
   * Simple class representing links. To be used as a template when using nested and parameterized classes.
   */
  render() {
    return <a href={this.props.link} className={this.props.CustomClass}>{this.props.teksti}</a>
      ;
  }
}
export default NavigationLink;
