import React from "react";
import MyGallery from './ImageGallery';

class Esittely extends React.Component {
  /*
  * Simple class representing links. To be used as a template when using nested and parameterized classes.
  */
  render() {
    return <>
      <div className="chapter">
      <h1>Marskin Kelohuvila</h1>
        <p>Marskin Majan läheisyydessä on kelohonkainen huvila, jossa voit majoittua, kokoustaa tai järjestää pienimuotoiset juhlat. Huvilassa on majoitustilaa 12 henkilölle. Majoitustilat ovat kolmessa makuuhuoneessa ja talvilämpimässä piha-aitassa. Huvilaan on mahdollisuus saada 5 lisävuodetta.</p>
        <p>Huvilan sauna on vain muutaman metrin päässä rannasta. Saunasta mukava pulahtaa uimaan puhtaaseen Punelia-järveen.</p>
        <p>Keittiön varustelutaso vastaa kotikeittiötä ja astiat on 24 henkilölle.</p>
      <MyGallery />
        
      </div>
    </>
      ;
  }
}
export default Esittely;
