import React from "react";
import NavigationLink from "./NavigationLink";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';

class Footer extends React.Component {
  
  render()  { return <>
     <Container>
   <br></br>
   <div className="s1">
    <Row>
     <Col>Marskin Kelohuvila</Col>
      <Col>Sivukartta</Col>
    </Row>
    
    
    <br></br>
    <Row>
      <Col>Marskintie 201</Col>
      <Col className="link-container"><div className="link-container-div"><NavigationLink CustomClass="footer-link" link="/" teksti="Etusivu"/></div></Col>
    </Row>
        <Row>
      <Col>12700 LOPPI</Col>
      <Col className="link-container"><div className="link-container-div"><NavigationLink CustomClass="footer-link" link="/tutustu" teksti="Tutustu"/></div></Col>
    </Row>
    <Row>
      <Col></Col>
      <Col className="link-container"><div className="link-container-div"><NavigationLink CustomClass="footer-link" link="/hinnasto" teksti="Hinnasto"/></div></Col>
    </Row>
    <Row>
      <Col>0400 167 327</Col>
      <Col className="link-container"><div className="link-container-div"><NavigationLink CustomClass="footer-link" link="/yhteystiedot" teksti="Yhteystiedot"/></div></Col>
    </Row>
    <Row>
      <Col className="link-container"><div className="link-container-div"><NavigationLink CustomClass="footer-link" link="mailto:info@marskinkelohuvila.fi" teksti="Email"/></div></Col>
      <Col></Col>
    </Row>
    </div>
    
  </Container>
  </>;
  }
}
export default Footer;
