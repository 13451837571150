import React from 'react';
import ImageGallery from 'react-image-gallery';

// images
import img1 from '../imgs/photo/img_1.JPG';
import img2 from '../imgs/photo/img_2.png';
import img3 from '../imgs/photo/img_3.JPG';
import img4 from '../imgs/photo/img_4.JPG';
import img5 from '../imgs/photo/img_5.JPG';
import img6 from '../imgs/photo/img_6.jpg';
import img7 from '../imgs/photo/img_7.JPG';
import img8 from '../imgs/photo/img_8.JPG';
import img9 from '../imgs/photo/img_9.JPG';
import img10 from '../imgs/photo/img_10.JPG';


// thumbs
import img1_thumb from '../imgs/thumb/img_1_thumb.JPG';
import img2_thumb from '../imgs/thumb/img_2_thumb.JPG';
import img3_thumb from '../imgs/thumb/img_3_thumb.JPG';
import img4_thumb from '../imgs/thumb/img_4_thumb.JPG';
import img5_thumb from '../imgs/thumb/img_5_thumb.JPG';
import img6_thumb from '../imgs/thumb/img_6_thumb.jpg';
import img7_thumb from '../imgs/thumb/img_7_thumb.JPG';
import img8_thumb from '../imgs/thumb/img_8_thumb.JPG';
import img9_thumb from '../imgs/thumb/img_9_thumb.JPG';
import img10_thumb from '../imgs/thumb/img_10_thumb.JPG';

// debugger
//console.log(img1);

const images = [
  {
    original: img1,
    thumbnail: img1_thumb,
  },
  {
    original: img2,
    thumbnail: img2_thumb,
  },
  {
    original: img3,
    thumbnail: img3_thumb,
  },
  {
    original: img4,
    thumbnail: img4_thumb,
  },
  {
    original: img5,
    thumbnail: img5_thumb,
  },
  {
    original: img6,
    thumbnail: img6_thumb,
  },
  {
    original: img7,
    thumbnail: img7_thumb,
  },
  {
    original: img8,
    thumbnail: img8_thumb,
  },
  {
    original: img9,
    thumbnail: img9_thumb,
  },
  {
    original: img10,
    thumbnail: img10_thumb,
  },
  
];

class MyGallery extends React.Component {
  render() {
    return <div className="img-gallery-div">
      <br></br>
        <ImageGallery
		items={images}
		originalHeight={true}
		originalWidth={true}
		//sizes="max-height: 1424px"
		/>
        </div>;
  }
}
export default MyGallery;
