import './App.css';
import React from 'react';

import { Route, Switch } from "react-router-dom"

import EtusivuPage from "./pages/EtusivuPage";
import HinnastoPage from "./pages/HinnastoPage";
import TutustuPage from "./pages/TutustuPage";
import YhteystiedotPage from "./pages/YhteystiedotPage";

import NavigationPane from "./components/NavigationPane";
import HeaderImageContainer from "./components/HeaderImageContainer";
// import MyGallery from './components/ImageGallery';
import LogoContainer from './components/LogoContainer';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      <LogoContainer />
      <NavigationPane />
      <HeaderImageContainer />
      <br></br>
      <Switch>
        
        <Route path="/tutustu">
          <TutustuPage/>
        </Route>

        <Route path="/hinnasto">
          <HinnastoPage/>
        </Route>
        
        <Route path="/yhteystiedot">
          <YhteystiedotPage/>
        </Route>

        <Route path="/">
          <EtusivuPage/>
        </Route>

      </Switch>
      <br></br>
      <div className="static">
      <Footer />

      </div>
    </div>
  );
}

export default App;
