import React from 'react';

class Hinnasto extends React.Component {
  /*
   * Simple class representing links. To be used as a template when using nested and parameterized classes.
   */
  render() {
    return <>
      <div className="chapter">
        <h1>Hinnasto</h1>
        <p>Kelohuvilan vuorokausihinta alkaen 500 euroa/vrk, omin liinavaattein ja pyyhkein.</p>
        <p>Viikonloppuhinta pe - su alkaen 780 euroa, omin liinavaattein ja pyyhkein. Viikkohinta alkaen 1500 euroa. Saunan käyttö sisältyy majoittuville asiakkaille hintaan. Päiväkokoushinta 260 e. Kokousasiakkaille saunan käytöstä annetaan hinta erikseen. Yli 10 henkilön majoittuville ryhmille ja yli 15 henkilön päiväryhmille hinta lasketaan erikseen. Vuodepaikkoja 17 kpl.</p>
        <p>Liinavaatteet ja pyyhkeet on mahdollista vuokrata meiltä. Vuoteet on mahdollista saada myös sijattuina. Näistä annamme hinnan erikseen.</p>
        <p>Hinnat eivät sisällä loppusiivousta. Loppusiivouksen hinta alkaen meidän kautta 120 euroa.</p>
      </div>
    </>
      ;
  }
}
export default Hinnasto;
