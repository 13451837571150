import React from "react";
import img_header from "../imgs/kelohuvila-logo.png"

class LogoContainer extends React.Component {
    /*
     * Kelohuvila Logo container.
     * TODO: Implement, currently DUMMY IMPLEMENTATION.
     */
    render() {
      return <div className="logo-div">
      <img className="logo-img" src={img_header} alt="Kelohuvila Logo"></img>
      </div>
    }
}
export default LogoContainer;
