import React from 'react';

class GoogleMap extends React.Component {
  /*
   * Imlements navigation pane with naive link.
   * TBD: Add React best-practices-like routing.
   */
  render() {
    return <>
      <div className="mapouter">
        <div className="gmap_canvas">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14734.163108173294!2d24.198564546055124!3d60.72073304424952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468e79847a48cbff%3A0xacdb401a26516a!2sMarskintie%20201%2C%2012750%20Loppi!5e0!3m2!1sen!2sfi!4v1688828527697!5m2!1sen!2sfi" width="600" height="450" style={ {"border":"0"} } allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </>
      ;
  }
}
export default GoogleMap;