import React from "react";

class Tutustuminen extends React.Component {
  /*
   * Simple class representing links. To be used as a template when using nested and parameterized classes.
   */
  render() {
    return <>
      <div className="chapter">
        <h1>Tutustu kelohuvilaan</h1>
        <p>Isossa (38 m2) tuvassa on mukava istua iltaa tai kokoustaa. Pitkän kelohonkapöydän ääreen sopii suurempikin joukko. Päiväkokoustilana huvila soveltuu 16 - 20 henkilölle. Huvilassa on WLAN-yhteys ja mahdollisuus saada myös dataprojektori.</p>
        <p>Päärakennuksen ja piha-aitan lisäksi pihapiiristä löytyy grillikatos ja rantasauna.</p>
        <p>Oma soutuvene kuuluu huvilan varustukseen.</p>
      </div>
    </>
      ;
  }
}
export default Tutustuminen;
