import React from "react";
import img_header from "../imgs/header.JPG"

class HeaderImageContainer extends React.Component {
    /*
        * Image container which scales the image to be rendered 100% screen width.
        * TODO: Implement, currently DUMMY IMPLEMENTATION.
        */
    render() {
        return <div className="header-cont-div">
        <img className="header-cont-img" src={img_header} alt="Kelohuvila" />
        </div>
    }
}

export default HeaderImageContainer;