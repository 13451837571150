import React from "react";
import GoogleMap from "./GoogleMap";
import NavigationLink from "./NavigationLink";

class Yhteystiedot extends React.Component {
  /*
   * Simple class representing links. To be used as a template when using nested and parameterized classes.
   */
  render() {
    return <>
      <div className="chapter">
        <h1>Yhteystiedot</h1>
        <p>Marskin kelohuvila</p>

        <h2>Käyntiosoite</h2>
        <p>
          Marskintie 201<br />
          12700 LOPPI<br />
        </p>

        <p>Loppumatkasta opastus numerokyltein perille asti.</p>

        <h2>Varaukset ja tiedustelut:</h2>
        <p>
        <NavigationLink link="mailto:info@marskinkelohuvila.fi" teksti="info@marskinkelohuvila.fi"/><br />
          0400-167 327<br />
        </p>


        <h2>Yrityksen postiosoite:</h2>
        <p>
          Punelian Lomakeskus Oy<br />
          Löyttysentie 176<br />
        </p>

        <p>14300 Renko</p>

        <GoogleMap />

      </div>
    </>
      ;
  }
}
export default Yhteystiedot;