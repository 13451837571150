import React from "react";
import NavigationLink from "./NavigationLink";

class NavigationPane extends React.Component {
  /*
   * Imlements navigation pane with naive link.
   * TBD: Add React best-practices-like routing.
   */
  render() {
    return <div className="nav">
      <ul className="nav-ul">
        <li className="nav-li"><NavigationLink link="/" teksti="Etusivu" /></li>
        <li className="nav-li"><NavigationLink link="/tutustu" teksti="Tutustu" /></li>
        <li className="nav-li"><NavigationLink link="/hinnasto" teksti="Hinnasto" /></li>
        <li className="nav-li"><NavigationLink link="/yhteystiedot" teksti="Yhteystiedot" /></li>
      </ul>
    </div>
      ;
  }
}

export default NavigationPane;